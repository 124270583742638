export const ru = {
  EDIT: 'Редактировать',
  UPDATE: 'Обновить',
  LANGUAGES: 'Языки',
  RU: 'Русский',
  EN: 'Английский',
  HINT: 'Подсказка',
  ANY: 'Любая',
  ANY_M: 'Любой',
  ERROR: 'Ошибка',
  WARNING: 'Внимание',
  ATTENTION: 'Внимание',
  SHOW: 'Показать',
  SHOW_EXTENDED_PERIOD: 'Посмотреть за весь период',
  DELETE: 'Удалить',
  SELECTIONS: 'Подборки',
  BOUNDARY_LOTS: 'Погранички',
  TOTAL_BOUNDARY: 'Всего пограничек: {{count}}',
  ON_DATE_BOUNDARY: 'Пограничек на {{date}}: {{count}}',
  ARE_YOU_SURE: 'Вы уверены?',
  ARE_YOU_SURE_DELETE: 'Вы уверены, что хотите удалить?',
  INFORMATION: 'Справочная информация',
  TRANSLATION: 'Перевод',
  AUTO: 'Автомобили',
  MOTO: 'Мото',
  SELECT_AUCTION: 'Выберите аукцион',
  COMPANY_MODEL: 'Марка, модель',
  SELECT_COMPANY: 'Выберите марку',
  COMPANY: 'Марка',
  SELECT_MODEL: 'Выберите модель',
  MODEL: 'Модель',
  ENGINE_VOLUME: 'Объем двигателя',
  SEE_ALL: 'смотреть все',
  PLEASE_SELECT: 'Выберите',
  SEARCH: 'Поиск',
  CLEAR: 'Сбросить',
  PHOTO: 'Фото',
  LOT: 'Лот',
  NO_LIST: 'Без списка',
  LOT_MANUALLY: 'Лот вручную',
  FILTER_DATA_OUTDATED: 'Данные фильтра могли устареть. Сбросьте фильтр для обновления данных',
  FILTERS: 'Фильтр',
  DATE: 'Дата аукциона',
  EQUIPMENT: 'Оборудование',
  SELECT_LOT_TYPE: 'Выберите тип лота',
  RATE_MUST_BE_HIGHER: 'Ставка должна быть больше либо равной стартовой цене',
  WRONG_YEAR: 'Не верный год',
  WRONG_BID_CONDITION: 'Не подходящее состояние ставки',
  TOTAL: 'Всего',
  SIGN_OUT: 'Выход',
  ACTIONS: 'Действия',
  AUCTION_NOT_STARTED: 'Аукцион ещё не начался',
  LOT_NOT_FOUND: 'Лот не найден',
  ONE_MORE_CHANCE: 'Еще один шанс',
  BID_PLACED: 'Ставка размещена!',
  BID_MAY_NOT_BID_PROCESSED: 'После 18:00 ваша ставка может быть не обработана',
  BID_DELETE_PROHIBITED_1700:
    'Данную ставку будет невозможно снять после 17:00 в день торгов по Токио',
  BID_DELETE_PROHIBITED_1100: 'Данную ставку будет невозможно снять после 11:00 в субботу по Токио',
  SOURCE: 'Источник',
  TRANSLATE_LOT: 'Перевести лот',
  PREVIOUS_SALES: 'Статистика продаж',
  GUIDANCE_REQUEST_CREATED:
    'Запрос на информирование по доступу к просмотру лотов и ставкам создан',

  PURCHASE_REPORT: 'Отчет по лоту',
  Statistics: 'Статистика',
  BIDS_LIST: 'Список ставок',
  MUTATION_FAILED: 'Не удалось',
  MUTATION_SUCCEEDED: 'Успешно',
  ENABLE_NEW_LOTS_NOTIFICATION: 'Включить уведомления о новых лотах',
  DISABLE_NEW_LOTS_NOTIFICATION: 'Выключить уведомления о новых лотах',

  AUCTION_REMOTENESS: {
    LOW: 'Удаленность аукциона - низкая',
    MEDIUM: 'Удаленность аукциона - средняя',
    HIGH: 'Удаленность аукциона - высокая',
  },

  FRAME_MODEL: 'Кузов',
  STATUS: 'Статус',
  ARTICLES: 'Статьи',
  TRANSMISSION: 'Трансмиссия',
  HOME: 'Главная',
  SEARCH_BY_BID: 'Поиск по номеру лота',
  INPUT_BID_NUMBER: 'Введите номер лота',
  BID_NUMBER: 'Номер лота',
  IN_CURRENCY: 'В выбранной валюте: <span class="text-green">{{amount}}</span>',
  AMOUNT: 'Ставка',
  GROUP: 'Группа',
  LOT_DATA: 'Данные лота',
  BIDS_DATE: 'Время ставки',
  MY_BIDS: 'Мои ставки',
  BID_STATUS: 'Состояние ставки',
  CONDITION: 'Состояние',
  Wed: 'ср',
  Thu: 'чтв',
  Fri: 'птн',
  Sat: 'сб',
  Sun: 'вс',
  Mon: 'пнд',
  Tue: 'вт',
  FROM: 'От',
  TO: 'До',
  SELECT_GROUP: 'Выбрать',
  FRAME_NUMBER: 'Номер кузова',
  DATE_MANUFACURE: 'Дата производства',
  CLOSE: 'Закрыть',
  CHECK: 'Проверить',
  CANCEL: 'Отмена',
  CREATE: 'Создать',
  SAVE: 'Сохранить',
  SAVED: 'Сохранено',
  CHECK_DATE_OF_MANUFACTURE: 'Проверить дату производства',
  DATE_MANUFACURE_NOT_FOUNDED: 'По такому номеру дата производства не найдена',

  UNDERSTANDING_THE_JAPANESE_AUCTION_SHEET: 'Справочник обозначений',
  SMALL_SCRATCH: 'Маленькая Царапина',
  SCRATCH: 'Царапина',
  BIG_SCRATCH: 'Большая Царапина',
  FEW_DIMPLES: 'Небольшая Вмятина',
  SEVERAL_DIMPLES: 'Несколько Небольших Вмятин',
  MANY_DIMPLES: 'Много Небольших Вмятин',
  SMALL_DENT: 'Маленькая Вмятина',
  DENT: 'Вмятина',
  BIG_DENT: 'Большая Вмятина',
  REPAIR_MARK_WAVE_HARDLY_DETECTABLE: 'Ремонт/Покраска (едва обнаружимые)',
  REPAIR_MARK_WAVE: 'Ремонт/Покраска (заметные)',
  OBVIOUS_REPAIR_MARK_WAVE_NEEDS_TO_BE_REPAINTED:
    'Ремонт/Покраска (очень заметные, должно быть перекрашено)',
  RUST: 'Малозаметная Ржавчина',
  HEAVY_RUST: 'Ржавчина',
  CORROSION: 'Коррозия',
  HEAVY_CORROSION: 'Заметная Коррозия',
  PAINT_MARKED: 'Краска отличается от оригинала',
  PAINT_FADED_X_NEED_TO_BE_REPLACED: 'Краска ухудшилась	X Элемент требует замены',
  REPLACED: 'Замененный Элемент',
  SMALL_DENT_WITH_SCRATCH_SIZE_LIKE_A_THUMB:
    'Маленькая вмятина с царапиной (размером с большой палец)',
  DENT_WITH_SCRATCH_SIZE_LIKE_FLAT_OF_THE_HAND: 'Вмятина с царапиной (размером с ладонь)',
  BIG_DENT_WITH_SCRATCH_SIZE_LIKE_ELBOW: 'Большая вмятина с царапиной (размером с локоть)',
  SMALL_HOLE_OR_CRACK: 'Маленькая Трещина',
  HOLE_OR_CRACK: 'Трещина',
  BIG_HOLE_OR_CRACK: 'Большая Трещина',
  SMALL_CRACK_ON_WINDSHIELD_APPROXIMATELY_1CM:
    'Маленькая Трещина на Ветровом стекле (приблизительно 1 см)',
  REPAIRED_CRACK_ON_WINDSHIELD: 'Восстановленная Трещина на Ветровом стекле',
  REPAIRED_CRACK_ON_WINDSHIELD_NEEDS_TO_BE_REPLACED:
    'Восстановленная Трещина на Ветровом стекле (требует замены)',
  CRACK_ON_WINDSHIELD_NEEDS_TO_BE_REPLACED: 'Трещина на Ветровом стекле (требует замены)',
  STONE_CHIP_IN_GLASS: 'Трещина в стекле от камня',

  DATE_OF_MANUFACTURE_HINT:
    'Внимание! Дата производства предоставляется сторонним поставщиком. Мы не гарантируем ее корректность.',
  DATE_OF_MANUFACTURE_WARNING:
    'За намеренный ввод неверного VIN номера  Вы будете лишены права проверять дату производства',
  EXTRA_DESCRIPTION: 'Дополнительное описание',
  INITIAL: 'Не обработана',
  CONFIRMED: 'Принята в обработку',
  CANCELED: 'Снята',
  WON: 'Лот куплен',
  TRANSLATED: 'Переведена',
  ALL: 'Все',
  LEFT_DRIVE: 'Левый',
  RIGHT_DRIVE: 'Правый',
  PRODUCTION_DATE: 'Дата выпуска (только проверенные)',
  CREATE_PRODUCTION_DATE_CHECK_REQUEST: 'Запросить дату производства',
  CREATED_AT: 'Дата создания',
  TODAY: 'Сегодня',

  ADD_PHOTO: 'Добавить фото',
  GET_PHOTO: 'Получить фото',
  GET_PHOTO_REQUEST_HINT: 'Может занять от 2 до 10 минут, в зависимости от текущей загруженности',
  PLEASE_SET_PASSWORD_0: 'Для входа можно использовать данные аккаунта ',
  REGISTRATION: 'Зарегистрироваться на',
  FOR_AUTH_USE_AKEOBONO_WORLD_ACCOUNT: 'Для авторизации используйте аккаунт akebono.world',
  PLEASE_SET_PASSWORD_1: 'Восстановите пароль',
  PLEASE_SET_PASSWORD_2: 'если входите первый раз.',
  VISITED: 'Просмотрен',
  FORBIDDEN_TITLE: 'Недостаточно прав для просмотра лотов',
  FORBIDDEN_MESSAGE:
    'Извините, у Вас нет прав на просмотр лотов. Пожалуйста обратитесть к своему аукционному менеджеру',
  SEND_REQUEST_TO_ACCESS: 'Отправить запрос на доступ',
  SEND: 'Отправить',
  LAST_NAME: 'Фамилия',
  INPUT_ERROR_LAST_NAME: 'Фамилия обязательное поле',
  INPUT_LAST_NAME: 'Введите вашу фамилию',
  FIRST_NAME: 'Имя',
  INPUT_ERROR_FIRST_NAME: 'Имя обязательное поле',
  INPUT_FIRST_NAME: 'Введите ваше имя',
  MIDDLE_NAME: 'Отчество',
  INPUT_ERROR_MIDDLE_NAME: 'Отчество обязательное поле',
  INPUT_MIDDLE_NAME: 'Введить свое отчество',
  SEND_REQUEST_TO_ACCESS_SUCCESS: 'Запрос на доступ к лотам отправлен',
  GUIDANCE_REQUEST_ALREADY_EXISTS:
    'Запрос на доступ к просмотру лотов отправлен. Ожидайте одобрения.',
  REQUEST_TYPE_NOT_SUPPORTED: 'Тип запроса не поддерживается',

  Success: 'Выполнено',
  'Bid placed!': 'Ставка принята!',
  'Please fill all required fields': 'Пожалуйста, заполните все необходимые поля',
  'Photo request sent!': 'Запрос фото отправлен!',
  'Sign in': 'Войти',
  'Send new password': 'Отправить новый пароль',
  Auto: 'Авто',
  Manual: 'Лоты вручную',
  Lot: 'Лот',
  'Moto lots': 'Мототехника',
  'Moto lot': 'Лот',
  SELLING_STATISTICS: 'Статистика продаж',
  SELECT: 'Выбрать',
  OLD_INTERFACE: 'Прежняя версия',
  ONEPRICE: 'OnePrice',
  FREE_SERVICE: 'Данная услуга предоставляется бесплатно',
  MODEL_GRADE: 'Комплектация',
  DAYS_TO_SHIP: 'Среднее время от покупки до погрузки на рейс',
  AVERAGE_TIME_FROM_BUY_TO_SHIP: 'Среднее время с {{auctionName}} от покупки до погрузки на рейс',
  DAYS: 'дней',
  Автомашина: 'Автомашина',
  'Мото техника': 'Мото техника',

  SCORE: {
    TITLE: 'Оценка ставки',
    RATE_0: 'Нет шансов',
    RATE_5: 'Очень низкая вероятность покупки',
    RATE_10: 'Очень низкая вероятность покупки',
    RATE_15: 'Очень низкая вероятность покупки',
    RATE_20: 'Очень низкая вероятность покупки',
    RATE_25: 'Очень низкая вероятность покупки',
    RATE_30: 'Низкая вероятность покупки',
    RATE_35: 'Низкая вероятность покупки',
    RATE_40: 'Низкая вероятность покупки',
    RATE_45: 'Средняя вероятность покупки',
    RATE_50: 'Средняя вероятность покупки',
    RATE_55: 'Средняя вероятность покупки',
    RATE_60: 'Средняя вероятность покупки',
    RATE_65: 'Высокая вероятность покупки',
    RATE_70: 'Высокая вероятность покупки',
    RATE_75: 'Высокая вероятность покупки',
    RATE_80: 'Высокая вероятность покупки',
    RATE_85: 'Высокая вероятность покупки',
    RATE_90: 'Очень высокая вероятность покупки',
    RATE_95: 'Очень высокая вероятность покупки',
    RATE_100: 'Очень высокая вероятность покупки',
  },
  DRIVER_CONFIG: {
    PREV_BTN: 'Назад',
    NEXT_BTN: 'Далее',
    DONE_BTN: 'Готово',
  },
  AUTO_LOT: {
    GUIDE: {
      TITLE_BLOCK: {
        TITLE: 'Заголовок лота',
        DESCR:
          'Основные данные по лоту. Производитель, модель и пробег, а так же данные по аукциону и дате торгов',
      },
      PHOTO_GALLERY: {
        TITLE: 'Фотогалерея',
        DESCR:
          'Фотографии объекта. В нижнем левом углу вам доступна кнопка с инструкциями по прочтению аукционных листов. Если у лота отсутствуют фотографии, воспользуйтесь кнопкой “Запросить фото“ или обратитесь к менеджеру',
      },
      ADDITIONAL_TAGS: {
        TITLE: 'Дополнительные теги лота',
        DESCR:
          'Дополнительные отметки лота, которые смогут предупредить вас о каких либо важных недочетах или преимуществах',
      },
      ACTION_BTNS: {
        FIRST: {
          TITLE: 'Кнопки действий',
          DESCR:
            'Основные действия с лотом. Вы можете открыть окно постановки ставки, скопировать ссылку на лот, запросить фото или перевод. Так же, здесь доступен переход к статистике продаж по похожим моделям и инструкция по прочтению некоторых обозначений по аукционным листам',
        },
        SECOND: {
          TITLE: 'Кнопки действий',
          DESCR:
            'Если вы запросили перевод по лоту, он будет доступен вам на странице лота парой блоков ниже, после того как наши сотрудники закончат работу с переводами',
        },
      },
      SELECTION: {
        TITLE: 'Подборка',
        DESCR:
          'Добавляйте автомобили в подборки, чтобы в будущем можно было вернуться к их просмотру. Все ваши подборки вы можете посмотреть на соответствующей странице, перейдя в неё через ссылку в шапке сайта',
      },
      DATE_OF_MANUFACTURE: {
        FIRST: {
          TITLE: 'Дата производства',
          DESCR:
            'Укажите в поле недостающую часть номера кузова с аукционного листа и запросите точную дату производства автомобиля. Дата будет доступна сразу при наличии в нашей базе, иначе, вы сможете отправить запрос на уточнение даты производства нашим менеджером',
        },
        SECOND: {
          TITLE: 'Дата производства',
          DESCR: 'Больше полезной информации о запросе даты вы можете узнать в ',
          LINK: 'нашем F.A.Q.',
        },
      },
      LOT_DATA: {
        TITLE: 'Данные лота',
        DESCR: 'Основные данные по транспортному средству. Название, объем, год, пробег и т.д.',
      },
      AUCTION_DATA: {
        TITLE: 'Данные аукциона',
        DESCR:
          'Название аукциона, номер лота и дата торгов. Так же вы можете прочитать отзывы по аукциону или оставить свой',
      },
      BIDS: {
        FIRST: {
          TITLE: 'Ставки',
          DESCR:
            'Здесь собраны основные ценовые показатели. Стартовая цена, размер вашей ставки, наличие ставок других участников, если они есть. Если ваша ставка в лоте лидировала, но по лоту открыты переговоры, вам необходимо будет подтвердить участие в переговорах. Кнопка для подтверждения будет доступна в этом же блоке',
        },
        SECOND: {
          TITLE: 'Ставки',
          DESCR:
            'Если вы поставили ставку с запросом перевода, то после получения перевода вам так же будет необходимо подтвердить участие в торгах',
        },
      },
      SELL_STATS: {
        TITLE: 'Статистика продаж',
        DESCR: 'Здесь вы можете посмотреть историю продаж и цен по этой модели авто',
      },
      ADDITIONAL_DESCR: {
        TITLE: 'Дополнительное описание',
        DESCR: 'Здесь могут быть расположены дополнительные данные по лоту, полученные от аукциона',
      },
      CALC: {
        TITLE: 'Калькулятор',
        DESCR: 'Поможет вам рассчитать ориентировочные расходы',
      },
    },
  },
  LOT_BIDDING_MODAL: {
    HIGHT_IMPORT_DUTY:
      'Высокая пошлина на ввоз в РФ. Пожалуйста, проконсультируйтесь с таможенным брокером перед покупкой',
    MAX_BID_AMOUNT_LIMIT: 'Ваша максимально возможная ставка: {{amount}}¥',
    BID_MUST_BE_ROUND: 'Ставка должна быть кратна 5 000',
    DAILY_LIMIT_REACHED: 'Лимит ставок на {{date}} достигнут',
    LOT_LIMIT_REACHED: 'Лимит ставок на этот лот достигнут',
    FILL_ALL_FIELDS: 'Пожалуйста, заполните все обязательные поля!',
    FAIL_CREATE_GROUP: 'Не удалось создать группу',
    SUCCESS_CREATE_GROUP: 'Группа создана',
    FAIL_CREATE_BID: 'Не удалось разместить ставку',
    SUCCESS_CREATE_BID: 'Ставка размещена!',
    PLACE_YOUR_BID: 'Сделать ставку',
    AMOUND_SHOULD_HINT: 'Сумма должна быть {{amount}} или больше',
    AMOUND_SHOULD_VALIDATION: 'Шаг минимальной ставки {{amount}}',
    YOUR_BID: 'Ваша ставка',
    NO_GROUP: 'Без группы',
    NEW_GROUP: 'Новая группа',
    QUANTITY: 'Кол-во',
    TENDER_AUCTION_NO_GROUP:
      'На тендерные аукционы невозможно делать групповые ставки. Ставки на тендерные аукционы принимаются за полтора часа до торгов',
    SELECT_CONDITIONS: 'Выберите состояние',
    YOUR_COMMENT: 'Комментарий',
    TRANSLATE_REQUEST: 'Перевести аукционный лист',
    NOTIFY_REQUEST: 'Уведомить о результатах торгов',
    NOTIFY_REQUEST_TOOLTIP:
      'Даже если ваша ставка не победит, вам придет уведомление с информацией о результате торгов',
    AGREEMENT_HINT1: 'Выбор лотов сделан собственноручно, с',
    AGREEMENT_HINT2: 'ознакомлен.',
    BID_RULES: 'правилами постановки',
    IN_ACCORDANCE_WITH: 'в соответствии с ',
    NAVIS_RULES_HINT1: 'Делая ставку, я подтверждаю, что ознакомлен и согласен с',
    NAVIS_RULES_HINT2: 'условиями разбора',
    NAVIS_RULES_TITLE: 'Правила разбора',
    LIMIT: 'Лимит',
    DOESNT_MAKE_SENSE: 'Шансы ставки на победу слишком низкие, вы точно хотите сделать ставку?',
    NEED_PRIORITY_PASS: 'Заказать экспресс-доставку в РФ',
    PRIORITY_PASS: 'Экспресс-доставка в РФ',
    GREEN_CORNER: 'Режим “Зеленый угол”',
    GREEN_CORNER_WARNING: 'Режим не работает с пограничными авто',
    DEFAULT_DELIVERY: 'Стандартная доставка в РФ',
    CAN_PACE_ONLY_ONE_BID:
      'До торгов осталось менее 60 минут. Вы можете поставить одну ставку без возможности поднять её позже.',
    IS_SANCTIONED_UTILIZATION_SURE: 'Состояние "Утилизация". Вы уверены?',
    WANT_PLACE_BID: 'Хотите сделать ставку?',
    CANT_CREATE_TRANSLATION: 'До торгов осталось менее часа. Запрос перевода невозможен',
    PRIORITY_PASS_PARAGRAPHS: {
      1: '3-4 недели ожидания от покупки на кнопке до получения во Владивостоке*',
      2: 'Автомобиль и документы будут максимально быстро доставлены с аукциона, отправка первым же возможным рейсом в РФ (при наличии экспортного сертификата) и возможность забрать автомобиль с готовым ЭПТС без очереди на СВХ на следующий день после выпуска авто от офиса компании Прим-Брокер**.',
      3: 'Стоимость услуги: 500$ + 50000 йен + 15000₽, оплата услуги в России при получении машины.',
      4: 'Заказать данную услугу можно также в Личном Кабинете из карточки объекта в течении суток после выигрыша лота.',
      5: 'Заказать данную услугу можно также в Личном Кабинете из карточки объекта в течении суток после выигрыша лота.',
      NOTES: {
        1: '*За исключением случаев, когда сроки доставки не могут быть сокращены по независящим от нас причинам, связанным с введением санкций, с судоходством, плохими погодными условиями, с задержкой документов на транспортное средство со стороны аукциона.',
        2: '**Услуга прохождения испытательной лаборатории, которая входит в экспресс доставку (Priority Pass), осуществляется только при соответствии автомобиля техническому регламенту. При наличии: рейлингов, фаркопа, нестандартного освещения, размера колёс и прочего нестандартного оснащения автомобиля, может последовать отказ в прохождении испытаний от лаборатории. В этом случае возможно увеличение сроков прохождения испытаний и стоимости услуги.',
      },
    },
    DISABLED_REASONS: {
      'data-changes': 'Данные лота изменились',
      'moto-overprice': 'Лот снят с торгов',
      'time-over': 'Истекло время. Ставки больше не принимаются',
      'bids-limit': 'Достигнут лимит ставок на лот',
      'lots-limit': 'Достигнут лимит лотов с вашими ставками',
      'chance-used': 'Ставка последнего шанса использована',
    },
    GUIDE: {
      BID_AMOUNT: {
        TITLE: 'Сумма ставки',
        DESCR:
          'Укажите желаемую сумму ставки. Система подскажет вам насколько высоки шансы победы с указанной суммой',
      },
      BIDS_GROUPING: {
        ZERO: {
          TITLE: 'Действие',
          DESCR: 'Нажмите на кнопку "Новая группа"',
        },
        FIRST: {
          TITLE: 'Группы ставок',
          DESCR:
            'Объединяйте ставки в группы, чтобы увеличить шансы приобрести авто. Пример - вы хотите участвовать в торгах по 5 машинам, но купить вам нужно только 2 из них.\n' +
            'Создайте группу с любой буквой и цифрой 2. Добавляйте нужные ставки в эту группу. Мы будем знать что в рамках группы вам нужно купить только 2 авто',
        },
        SECOND: {
          TITLE: 'Группы ставок',
          DESCR:
            'У всех лотов в группе должна быть одна дата торгов. Иначе, вы рискуете приобрести лишние авто. После того как хотя бы одна группа будет создана, \n' +
            'слева от кнопки вы увидите поля для выбора уже созданной группы.',
        },
        CLOSE: {
          TITLE: 'Действие на удаление',
          DESCR: 'Нажмите на эту кнопку чтобы удалить группу',
        },
      },
      CALC: {
        TITLE: 'Калькулятор',
        DESCR: 'Калькулятор подскажет вам примерные расходы по Японии в рамках лота',
      },
      CONDITION: {
        TITLE: 'Состояние',
        DESCR:
          'Выберите состояние, в котором транспортное средство будет доставлено в порт назначение',
      },
      COMMENT: {
        TITLE: 'Комментарий',
        DESCR: 'Оставьте комментарий для нас, если хотите уведомить о чем то важном в рамках лота',
      },
      LOT_TRANS: {
        FIRST: {
          TITLE: 'Перевод лота',
          DESCR:
            'Воспользуйтесь услугой перевода лота для уточнения информации по объекту. Выберите предпочитаемый язык перевода в настройках профиля',
        },
        SECOND: {
          TITLE: 'Перевод лота',
          DESCR:
            'Как только перевод поступит, вам необходимо с ним ознакомиться и подтвердить участие в торгах. Иначе, ваша ставка будет недействительна',
        },
      },
      NOTIFY_RES: {
        TITLE: 'Уведомление о результате',
        DESCR:
          'Если хотите получить уведомление по результатам торгов, активируйте этот пункт. Уведомление поступит вам на почту и в Telegram, \n' +
          'если вы подключили его в настройках',
      },
      BIDS_RULES: {
        TITLE: 'Правила постановки',
        DESCR:
          'Для участия в торгах необходимо ознакомиться и принять условия постановки и обработки ставок',
      },
      GREEN_CORNER: {
        TITLE: 'Режим "Зеленый угол"',
        DESCR:
          'Активируйте этот пункт если хотите приобрести это авто в Режиме "Зеленый угол". Обратитесь к менеджеру для получения дополнительной информации',
      },
      FINISH: {
        TITLE: 'Инструктаж закончен',
        DESCR: 'Спасибо за внимание!',
      },
    },
  },

  ARTICLE: {
    ARTICLE_NOT_FOUND: 'Статья не найдена',
    TRANSLATE_NOT_FOUND: 'Перевод для вашего языка отсутствует',
    CATEGORIES: 'Категории',
    OPEN_CATEGORY_MENU: 'Открыть список категорий',
    ARTICLE_LOAD_ERROR: 'Ошибка при загрузке статьи',
    CATEGORIES_LOAD_ERROR: 'Ошибка при загрузке категорий',
  },

  USER_REQUESTS: {
    LANGUAGE: 'Язык',
    CREATE: 'Отправить запрос',
    CREATE_SUCCESS: 'Запрос успешно создан',
    CREATE_FAIL: 'Не удалось создать запрос',
    MESSAGE: 'Сообщение',
    TRANSLATION_MEDIA_TYPE: 'Тип перевода',
    ALERT:
      'Перевод лота будет осуществлен в порядке очереди. Приоритет отдается лотам с ближайшими датами торгов',
    SATURDAY_TIME_LIMIT_ALERT: 'Запросы на перевод по субботам принимаются до 11:00 по Японии',
    LOT_TRANSLATE_REQUEST: 'Запросить перевод',
    BID_DELETE_REQUEST: 'Запросить удаление ставки',
    BID_PLACE_COMMENT: 'Комментарии',
    BID_DELETE_WARNING:
      'Запрос удаления ставки не значит фактическую отмену ставки, в некоторых случаях ставка не может быть отменена. Снятие ставки после 17:00 по будням и 11:00 в субботу по Токийскому времени невозможно.',
    BID_DELETE_WARNING2: 'Внимание:',
    BID_DELETE_WARNING3:
      'Если Вы желаете отменить все ставки на лот, то необходимо создать запрос на каждую',
  },

  UPLOAD_PHOTOS: {
    SUCCESS: 'Фото успешно загружены',
    FAIL: 'Не удалось загрузить фото',
  },

  BID_COMMENTS: {
    PLACEHOLDER: 'Введите текст комментария',
    SEND: 'Отправить',
    YOU: 'Вы',
    MANAGER: 'Менеджер',
    SUCCESS: 'Комментарий успешно добавлен',
    FAIL: 'Не удалось добавить комментарий',
    ENTER_COMMENT: 'Введите комментарий',
  },

  LOT_CAROUSEL: {
    DELETE_PHOTO: 'Вы уверены, что хотите удалить фото ?',
    DELETE_SUCCESS: 'Фото успешно удалено',
    DELETE_FAIL: 'Не получилось удалить фото',
    MOVE_LEFT: 'Переместить влево',
    MOVE_RIGHT: 'Переместить вправо',
    SAVE_CHANGES: 'Сохранить изменения',
    DELETE_PHOTO_TOOLTIP: 'Удалить фото',
    AUCTION_LIST_TIP_MODAL: {
      TITLE: 'Как читать аукционные листы?',
      TIPS: {
        GENERAL: {
          LOT_NUM: 'Номер лота',
          VEHICLE_PARAMS: 'Параметры автомобиля',
          FIRST_REG_DATE: 'Дата первой регистрации',
          ENGINE_CAPACITY: 'Объем двигателя',
          MODEL_NAME: 'Название модели',
          CAR_NAME: 'Название авто',
          CAR_HISTORY: 'История автомобиля',
          CHASSIS_MODEL: 'Модель кузова',
          CHASSIS: 'Кузов',
          CHASSIS_MODIFICATION: 'Модификация кузова',
          CHASSIS_NUM: 'Номер кузова автомобиля',
          CHASSIS_GRADE: 'Оценка кузова',
          CHASSIS_TYPE: 'Тип кузова',
          TECH_INSPECTION_DEADLINE: 'Срок техосмотра',
          DOORS: 'Кол-во дверей',
          MODEL_VERSION: 'Комплектация',
          DRIVETRAIN_TYPE: 'Тип привода',
          INTERIOR: 'Салон',
          INTERIOR_GRADE: 'Оценка салона',
          EXTERIOR_GRADE: 'Внешняя оценка',
          MILEAGE: 'Пробег',
          GRADE: 'Аукционная оценка',
          VEHICLE_COLOR: 'Цвет машины',
          ORIGINAL_COLOR: 'Оригинальный цвет',
          SHIFTER_LOCATION: 'Расположение ручки КПП',
          COLOR_CODE: 'Код цвета автомобиля',
          INTERIOR_COLOR: 'Цвет салона',
          INTERIOR_SEATS: 'Салон, сиденья',
          YEAR: 'Год',
          MODEL_YEAR: 'Модельный год',
          IMPORT_TYPE: 'Дилер',
          IMPORT: 'Импорт',
          IMPORT_HISTORY: 'История импорта',
          IMPORT_CLASS: 'Классификация импорта',
          USE_TYPE: 'Тип использования авто',
          PERSONAL_RENT: 'Личное/Аренда',
          MONTH_DAY: 'Месяц день',
          MONTH: 'Месяц',
          DAY: 'День',
          STEERING_WHEEL: 'Расположение руля',
          STEERING_WHEEL_CONDITION: 'Состояние руля',
          LEFT_RIGHT: 'Слева/Справа',
          TECH_INSP: 'Техосмотр',
          RECYCLING_FEE: 'Утилизационный взнос',
          RECYCLE_TICKET: 'Утилизационный билет',
          SEATS: 'Количество мест',
          SEATING_CAPACITY: 'Вместительность',
          CARRYING_CAPACITY: 'Грузоподъемность',
          TRANSMISSION: 'Трансмиссия',
          ORIGINAL_EQUIPMENT: 'Оригинальное оборудование',
          VEHICLE_DIMENSIONS: 'Габариты машины',
          SUNROOF: 'Люк',
          LEATHER_INTERIOR: 'Кожаный салон',
          LEATHER_SEATS: 'Кожаные сидения',
          SEATS_CONDITION: 'Состояние сидений',
          ALLOY_WHEELS: 'Литые диски',
          TV: 'TV',
          TV_ORIGINAL: 'TV-родное',
          AUDIO: 'Аудиосистема',
          WHEEL_COVERS: 'Колёсные колпаки',
          BODY_KIT: 'Обвес',
          SIDE_MIRRORS: 'Боковые зеркала',
          ABRASIONS: 'Потёртости',
          NOT_WORK_ABSENT: 'Не работает, отсутствует',
          FUEL_TYPE: {
            TITLE: 'Тип топлива',
            DESCR: 'Слева обведен бензин в нашем случае, справа дизель через точку',
          },
          RE_REG_DEADLINE: {
            TITLE: 'Срок переоформления документов',
            DESCR: 'Дата, до которой автомобиль должен быть перерегистрирован',
          },
          WILL_BE_SENT_LATER: {
            TITLE: 'Будет отправлено позже',
            DESCR: 'Дополнительные товары, которые будут отправлены',
          },
          DIMENSIONS: {
            TITLE: 'Размеры авто',
            DESCR: 'Длина, ширина, высота',
          },
          PURCHASED_FROM_OWNER: {
            TITLE: 'Куплено у собственника',
            DESCR: 'Да/Нет',
          },
          CONDITIONER: 'Кондиционер',
          POWER_STEERING: 'Гидроусилитель руля',
          GPS: 'Навигация',
          POWER_WINDOWS: 'Электростеклоподъемники',
          AIRBAG: 'Подушка безопасности',
          ABS: 'ABS',
          TUNER: 'Tuner',
          FW: 'FW',
          SELLER_NOTES: 'Отметки продавца',
          PLATE_NUM: 'Регистрационный номер',
          SERIAL_NUM: 'Серийный номер',
          NOTES: 'Отметки',
          INSPECTOR_NOTES: 'Отметки инспектора',
          MARKED_AVAILABLE: 'Обводить только, если родное',
          WINDSHIELD: 'Лобовое стекло',
          SCRATCHES: 'Царапины',
          REPLACEMENT_REQUIRED: 'Под замену',
          LENGTH: 'Длина',
          WIDTH: 'Ширина',
          HEIGHT: 'Высота',
          DAMAGE_SCHEME: {
            TITLE: 'Схема повреждений',
            DESCR: 'Подробнее об отметках повреждений вы можете',
            LINK: 'узнать по ссылке',
          },
          SPARE_WHEEL: 'Наличие запасного колеса',
          INSURANCE: 'Страховка',
          NEW_CAR_INSURANCE: 'Страховка новой машины',
          JP_DOCS: 'Японские документы',
          DEALER_SELF_IMPORT: 'Дилер/Импорт',
          CAR_OPTIONS: 'Название/кол-во дверей/тип/комплектация',
          A_SCRATCHES: 'А - царапины',
          X_CRACKS_BROKEN: 'Х – трещины, поломаны',
          A_SCRATCHES_BROKEN: 'А – царапины, поломан',
          SCRATCHES_NEED_REPLACE: 'Царапины・Требует замены',
          SERVICE_BOOK: {
            TITLE: 'Сервисная книжка',
            DESCR: 'Есть/Нет',
          },
          COLOR_DESCR_1: '元色 - оригинальный цвет',
          COLOR_DESCR_2: '色替 - цвет изменен',
        },
        USS: {
          CAR_HISTORY: {
            TITLE: 'История автомобиля',
            DESCR: 'Использовалось ли им частное лицо или его арендовали, или другой вариант',
          },
          FIRST_REG_JP_CHRONO: 'Первая регистрация по японскому летоисчислению',
          TECH_INSPECTION_VALIDITY_PERIOD: {
            TITLE: 'Срок действия техосмотра',
            DESCR:
              'Дата, до которой действует талон техосмотра в Японии. Выдается новой машине на 3 года',
          },
          CONDITIONER: {
            TITLE: 'Кондиционер',
            DESCR:
              'В данном случае это кондиционер плюс система фильтрации и подогрева, оснащенная несколькими датчиками и электронным блоком климат-контроля',
          },
          DOCS_VALIDITY_PERIOD:
            'Срок действия документов, до которого купленный автомобиль необходимо переоформить. Это актуально только для автомобилей с номерными знаками.',
        },
        T_C_AA: {
          LOT_NUM: {
            TITLE: 'Номер лота',
            DESCR: 'Под лотом отметка - впервые авто на аукционе или нет',
          },
          TAA_AUCTION_NOTES: 'Отметки от офиса TAA',
          CAA_AUCTION_NOTES: 'Отметки от офиса CAA',
          CAR_COLOR_DESCR: 'Справа отметка, была машина перекрашена или нет',
        },
        JU: {
          MODEL_VERSION: 'Модель/комплектация',
          COLOR_N_COLOR_CODE: 'Цвет машины и код цвета',
          CAR_DISADVANTAGES_WINDSHIELD:
            'Царапины, скол от камней, трещины, следы ремонта, требуется замена',
          CAR_DISADVANTAGES_INTERIOR:
            'Царапины, загрязнение, прожоги, дырки, потертости, порезы, порваны',
        },
        LAA: {
          DEALER_INSURANCE: 'Дилерская страховка',
          OWNERS_MANUAL: 'Руководство по эксплуатации',
          INTERIOR_OPTIONS: 'Царапины • Прокурен • Дырки • Грязь • Порван',
          KEYS_OFFICE: {
            TITLE: 'Ключи в офисе аукциона',
            DESCR:
              'Если этот флажок установлен, ключи хранятся в аукционном офисе. Автомобиль закрыт',
          },
        },
        ARAI: {
          RIGHT_STEERING_WHEEL: 'Правый руль',
          INSPECTOR_NOTES_DESCR_1: 'Первая строка - Информация на ходу машина или нет',
          INSPECTOR_NOTES_DESCR_2:
            'Вторая строка - Информация о заборе авто, если авто находится на другом ярде',
          INSPECTOR_NOTES_DESCR_3: 'Далее прочие отметки инспектора',
        },
        HERO: {
          NAME_MODEL: 'Название и комплектация',
          WARNING: 'Внимательно!',
          G_GASOLINE: 'G-Бензин',
          YEN: 'Йен',
        },
        IAA_OSAKA: {
          ODOMETER_PROBLEMS: {
            TITLE: 'Проблемы с одометром',
            DESCR: 'Заменил - Пробег скручен - Пробег неизвестен',
          },
          NOX: {
            TITLE: 'NOx(оксид азота)',
            DESCR: 'Соответствует или не соответствует стандартам',
          },
          SELLER_TYPE: {
            TITLE: 'Классификация импорта',
            DESCR: 'Дилер-Импорт',
          },
          X_OPTIONS: 'Х – сколы, трещины, требуется замена',
          CAR_DISADVANTAGES: 'Прокуры, трещины, царапины, загрязнения, потёртости',
          SCRATCHES_ABRASIONS: 'Царапины-Потёртости',
        },
        MIRIVE: {
          INNER_AUCTION_NAME: 'Название внутреннего аукциона',
          REGISTRATION: 'Постановка на учет',
          PRECAUTIONARY_MEASURES: 'Меры предосторожности',
          RE_REG_PERIOD: 'Период перерегистрации',
          BUY_CAR: 'Покупка авто',
          DEALER_MILEAGE: 'Дилер ・ Пробег',
          LEFT_RIGHT_STEERING_WHEEL: 'Левый или правый руль',
          WHEELS_SCRATCHES_BROKEN: 'Дверные зеркала (царапины/разбиты)',
          REAR_VIEW_MIRRORS: 'Состояние кузова (мелкие царапины/мелкие вмятины/требуется ремонт)',
          ADDITIONALLY: 'Дополнительно',
        },
      },
      BUTTON: {
        TOOLTIP_TITLE: 'Нажмите для подробной информаций об аукционных листах',
      },
    },
  },

  LOT_INFO: {
    TRUCK: 'Грузовик',
    TITLE: 'Лот',
    NAME: 'Название',
    AUCTION: 'Аукцион',
    AUCTION_DATE: 'Дата аукциона',
    YEAR: 'Год',
    CHASSIS_ID: 'Кузов',
    COLOR: 'Цвет',
    ENGINE_CC: 'Двигатель',
    MILEAGE: 'Пробег',
    INSPECTION: 'Инспекция',
    SCORE: 'Оценка',
    START_PRICE: 'Старт',
    SOLD_FOR: 'Продано за',
    TRADING_RESULT: 'Результат торгов',
    AVERAGE_PRICE: 'Средняя цена',
    YOUR_BID: 'Ваша ставка',
    RECOMMENDED_BID_AMOUNT: 'Рекомендуемая ставка',
    RECOMMENDED_BID_NO_FRESH_DATA: 'Нет свежих данных',
    APPROVE_PARTICIPATION: 'Подтвердить участие',
    DENY_PARTICIPATION: 'Отменить ставку',
    PARTICIPATION_ACCEPTED: 'Участие подтверждено',
    PARTICIPATION_CANCELED: 'Участие отменено',
    NEGOTIATIONS_PRICE: 'Цена начала переговоров',
    APPROVE_PARTICIPATION_IN_NEGOTIATIONS: 'Подтвердить участие',
    NEGOTIATIONS_ACCEPTED: 'Переговоры подтверждены',
    COUNTDOWN_TO_FINISH: 'До завершения',
    WAS_OVERBID: 'Вы не лидер',
    END_SOON_HINT: 'Приём ставок на лот окончен!',
    BIDDING_OVER: 'Торги окончены!',
    FIXED_PRICE: 'Цена',
    BEST_BID: 'Ставка другого участника',
    PRODUCTION_DATE: 'Дата производства',
    EXISTS: 'Есть',
    NOT_EXISTS: 'Нет',
    DRIVE: 'Руль',
    GROUP: 'Группа',
    GEARBOX_TYPE: 'Тип КПП',
    OBJECT_DATA: 'Данные лота',
    BIDS: 'Ставки',
    GUIDE: {
      MY_BIDS: {
        TITLE: 'Страница ставок',
        DESCR:
          'Вы находитесь на странице с вашими ставками. Здесь вы можете ознакомиться с текущими и старыми ставками. Здесь же вы можете отменить свои текущие ставки',
      },
      FILTER: {
        TITLE: 'Фильтр',
        DESCR: 'Используйте фильтр чтобы  найти необходимые ставки по заданным параметрам',
      },
      STATUS: {
        TITLE: 'Статус',
        DESCR: 'Используйте навигацию между вашими ставками исходя из их статуса',
      },
      BIDS_LIST: {
        TITLE: 'Список ставок',
        DESCR: 'Здесь будут отображены ваши ставки исходя из указанных выше параметров',
      },
      COMMENT: {
        TITLE: 'Комментарий',
        DESCR: 'Вы можете оставить комментарий с необходимой информацией для нас по нужной ставке',
      },
      DELETE_BID: {
        TITLE: 'Удаление ставки',
        DESCR:
          'Вы можете оставить нам запрос на удаление ставки, если не хотите участвовать в торгах.\n' +
          'Обратите внимание, если по одному лоту у вас есть несколько ставок, то запрос на удаление вам необходимо отправить по каждой из них',
      },
      FINISH: {
        TITLE: 'Инструктаж закончен',
        DESCR: 'Спасибо за внимание!',
      },
    },
  },

  GEARBOX_TYPES: {
    automatic: 'Автоматическая',
    manual: 'Механическая',
  },

  LOT_PHOTO_REQUEST: {
    SEND: 'Запросить фото',
    SUCCESS: 'Запрос успешно отправлен',
    FAIL: 'Не удалось отправить запрос',
    ARAI_SUCCESS: 'Фото успешно загружены',
    ARAI_FAIL: 'Не удалось загрузить фото',
  },

  PRODUCTION_DATE_PAYLOAD: {
    date: 'Дата производства',
    trimcode: 'Код обивки',
    modelname: 'Модель',
    modelcode: 'Код модели',
    engineno: 'Двигатель',
    colorcode: 'Код цвета',
    code: 'Описание',
    catalogno: 'Номер в каталоге',
    transcode: 'Трансмиссия',
    vincode: 'Vin номер',
    modelcat: 'Категория',
    blockcode: 'Параметры двигателя',
    gradecode: 'Модификация',
    colorrgb: 'Цвет RGB',
    colorname: 'Название цвета',
  },

  NOTIFICATION_SETTINGS: {
    TITLE: 'Оповещения',
    FULL_TITLE: 'Настройка оповещений',
    NAME: 'Название оповещения',
    CONNECT_TELEGRAM: 'Подключить уведомления через Telegram',
    TELEGRAM_NOT_CONNECTED: 'Telegram аккаунт не подключен',
    NAMES: {
      AkebonoBidBought: 'Покупка лота',
      AkebonoBidTranslationCompleted: 'Перевод выполнен',
      AkebonoBidWasOverbid: 'Перебитие ставки',
    },
  },

  BIDS_SETTINGS: {
    TOOLTIPS: {
      HIDE_MY_BIDS_AMOUNT_HINT: `При включении другие пользователи не будут видеть размер вашей ставки, но и Вы не сможете видеть размер ставки других пользователей.`,
      HIDE_BIDS_ATTENTION: 'Внимание! Изменить эту настройку можно только один раз.',
    },
    KEYS: {
      hideAmountBid: 'Скрывать размер моей ставки',
      bidTranslationByDefault: 'Запрашивать перевод лота при ставке',
      subscribeToViewBestBid: 'Показывать ставку другого клиента',
      ignoreSmallBidWarning: 'Игнорировать предупреждение о низком шансе победы ставки',
    },
  },

  TRANSLATION_SETTINGS: {
    DEFAULT_TRANSLATION_MEDIA_TYPE: 'Тип перевода по умолчанию',
    DEFAULT_AUDIO_TRANSLATION_SPEED: 'Стандартная скорость воспроизведения перевода',
    PREFERRED_LANGUAGE_FOR_TRANSLATION_AND_NOTIFICATION:
      'Предпочитаемый язык перевода и уведомлений',
    TRANSLATION_MEDIA_TYPES: {
      AUDIO: 'Голосовой',
      TEXT: 'Текстовый',
    },
  },

  SETTINGS: {
    NAME: 'Название настройки',
    VALUE: 'Значение',
    TITLE: 'Настройки',
    NOTIFICATIONS: 'Оповещения',
    BIDS: 'Ставки',
    OTHER: 'Остальное',
    TRANSLATIONS: 'Переводы',
  },

  LOT_TRANSLATION: {
    ICONS: {
      damaged: 'Неисправность',
      forklift: 'Не на ходу',
      repainted: 'Перекрашено',
      check: 'Чек двигателя',
      equipped_for_disabled: 'Для инвалидов',
      drowned: 'Утопленное',
      sanctions: 'Санкции',
      dashboard_lamp_on: 'Горит лампа',
      not_left_drive: 'Не левый руль',
    },
    ICONS_TOOLTIPS: {
      damaged: 'Неисправность в автомобиле. Уточните у менеджера',
      forklift: 'Авто не может передвигаться самостоятельно',
      repainted: 'Авто не в родной краске',
      check: 'Горит чек на панели приборов',
      equipped_for_disabled: 'Авто оборудовано подъемником или пандусом',
      drowned: 'Авто утопленное',
      sanctions: 'Авто находится в списке санционных',
      dashboard_lamp_on: 'На панели приборов горит одна или несколько ламп',
      not_left_drive: 'Авто не с левым рулем',
    },
  },

  SPECIAL_BID_ALERT: {
    ONE: {
      FIRST_LINE: 'Уважаемые клиенты!',
      SECOND_LINE:
        'В связи с резким скачком цен на бензин в Японии у нас изменились расходы в калькуляторе. Просим ознакомиться с новыми тарифами.',
    },
    TWO: {
      SECOND_LINE:
        'Напоминаем, что приобретенный лот необходимо оплатить в течение 3х рабочих дней. В случае, если оплата за приобретенный Вами лот не поступает на наш счет в Японии в течение 10 дней, Вам будет выставлено пенальти в размере 1% от суммы долга ежедневно до момента погашения задолженности. Исключением являются случаи, когда платеж попадает под проверку в японском банке.',
    },
  },

  LOT_STATUSES: {
    STATUS: 'Статус лота',
    INITIAL: 'Необработан',
    SOLD: 'Продан',
    NEGOTIATIONS: 'Переговоры',
    WON: 'Куплен',
    CANCELED: 'Отменен',
    UNSOLD: 'Не продан',
    NEGOTIATION_SOLD: 'Продан по переговорам',
    REMOVED: 'Удален',
  },

  BOUNDARY_INFO: {
    NOT_BOUNDARY: 'Ввезти в РФ до {{date}}',
    EARLY: 'Ввезти в РФ после {{date}}',
    BOUNDARY: 'Пограничная! Ввезти в РФ до {{date}}',
    OVERDUE: 'Не проходная',
  },

  INTERESTING: {
    TRUE: 'Добавить лот в избранное',
    FALSE: 'Отметить лот, как мне заведомо неинтересный',
  },

  SPECIAL_ALERTS: {
    HAS_DEBTS:
      'Убедительная просьба сообщать об оплате автомобиля в личном кабинете в день совершения банковского перевода. В противном случае,  Ваш платёж может попасть в неопознанные и на машину будет начисляться пенальти.',
  },

  SHARED: {
    SHARE: 'Поделиться',
    SHARE_LOT: 'Поделиться лотом',
    SHARE_LIST: 'Поделиться подборкой',
    COPY_SHARED_SUCCESS: 'Ссылка на лот была скопирована в буфер обмена',
    COPY_LIST_SUCCESS: 'Ссылка на подборку была скопирована в буфер обмена',
    OPEN: 'Открыт',
    CLOSE: 'Закрыть',
    SELECT_LIST: 'Выбрать подборку',
    ADD_NEW_LIST: 'Добавить новую подборку',
    LISTS_INCLUDES: 'Лот внесен в подборку',
    LIST_ADD: 'Добавить',
    MOVE: 'Переместить',
    MOVE_TO: 'Переместить в',
    DELETE_LIST: 'Удалить подборку',
    CLOSE_ACCESS: 'Закрыть доступ',
    VIEW_ALL: 'Посмотреть все',
  },

  CHANNEL: {
    LABEL: 'Канал',
    DELETE: 'Удалить канал',
    EDIT: 'Редактировать канал',
    CREATE: 'Создать канал',
    CHANNEL_TITLE: 'Название канала',
    COMPANY_NAME: 'Название компании',
    DESCRIPTION: 'Описание',
    UPLOAD_LOGO: 'Загрузить логотип',
  },

  MANUAL_LOTS: {
    LABEL: 'Дополнительно',
    UPLOAD: 'Загрузить фото',
    CREATE: 'Создать лот',
    ADD_LOT: 'Добавить лот',
  },

  FILTER: {
    FILTER: 'Фильтр',
    RESET: 'Сброс',
    SEARCH: 'Поиск',
    REQUIRED: 'Обязательно',
  },

  MILEAGE_FILTER: {
    FROM: 'Пробег от',
    TO: 'Пробег до',
  },

  YEAR_FILTER: {
    FROM: 'Год от',
    TO: 'Год до',
  },

  LOT_CHANGES: {
    TITLE: 'История изменений лота',
    BUTTON_TITLE: 'Логи лота',
    DATE: 'Дата',
    DESCRIPTION: 'Описание',
    VERSION: 'Версия',
    AUTHOR: 'Автор',
    CHANGES: {
      CREATED: 'Лот создан',
      TRANSLATIONS_COUNT: 'Кол-во перевод изменено:',
      ICONS: 'Иконки изменены:',
      VIN: 'Vin изменен:',
      PRODUCTION_DATE: 'Дата производства изменена:',
      IMAGES: 'Картинки обновлены. Текущее количество:',
      THUMBNAILS: 'Эскизы обновлены. Текущее количество:',
      PHOTOS_FETCHED: 'Фото лота загружены',
      STATUS: 'Статус изменен:',
      UPDATED: 'Лот обновлен',
      START_PRICE: 'Стартовая цена изменена:',
      SOLD_PRICE: 'Цена продажи изменена:',
      NEGOTIATIONS_PRICE: 'Цена по переговорам изменена:',
      AUCTION_DATETIME: 'Дата торгов обновлена:',
    },
  },

  ARAI_KENKI: {
    LABELS: {
      EXCAVATOR: 'Экскаваторы',
      MINI_EXCAVATOR: 'Мини-экскаваторы',
      WHEEL_LOADER: 'Колесные погрузчики',
      MINI_WHEEL_LOADER: 'Колесные мини-погрузчики',
      SKID_STEER_LOADER: 'Погрузчик с бортовым поворотом',
      GRADER: 'Автогрейдеры',
      ASPHALT_FINISHER: 'Асфальтоукладчики',
      CRANE: 'Краны',
      ROUGH_TERRAIN_CRANE: 'Короткобазные краны',
      TRUCK_CRANE: 'Автомобильные краны',
      ALL_TERRAIN_CRANE: 'Вездеходные краны',
      AERIAL_WORK_PLATFORM: 'Подъемные платформы для высотных работ',
      CRAWLER_CRANE: 'Гусеничные краны',
      MINI_CRAWLER_CRANE: 'Гусеничные мини-краны',
      COMPRESSOR: 'Компрессоры',
      MOBILE_CRUSHER: 'Мобильные дробилки',
      ATTACHMENTS: 'Навесное оборудование',
      ATTACHMENTS_ETC: 'Прочее навесное оборудование',
      BLADE: 'Отвалы',
      BUCKET: 'Ковши',
      BREAKER: 'Гидромолоты',
      CUTTER: 'Гидроножницы',
      CRUSHER: 'Бетоноломы',
      CONCRETE_CUTTER: 'Фреза',
      CONSTRUCTION_MISCELLANEOUS: 'Различное строительное оборудование',
      VIBRO: 'Виброинструменты',
      VIBRO_HAMMER: 'Вибро молот',
      FORK: 'Гидравлический захват',
      FORKLIFT_NAIL: 'Вилы',
      FLOODLIGHT: 'Прожектора',
      MAGNET: 'Магниты',
      PLATE: 'Виброплиты',
      RAMMER: 'Трамбовчщики',
      BULLDOZER: 'Бульдозеры',
      SHOVEL_LOADER: 'Ковшовый погрузчик',
      FORKLIFT: 'Вилочные погрузчики',
      BATTERY_FORKLIFT: 'Электрические вилочные погрузчики',
      REACH_FORKLIFT: 'Рич-трак',
      RICE_TRANSPLANTER: 'Рассадо- посадочные машины для риса',
      ROLLER: 'Катки',
      COMBINE_ROLLER: 'Комбинированные катки',
      SOIL_ROLLER: 'Грунтовые катки',
      TIRE_ROLLER: 'Пневмоколесные катки',
      TANDEM_ROLLER: 'Двухвальцовые (тандемные) катки',
      HAND_GUIDED_ROLLER: 'Ручной каток',
      MACADAM_ROLLER: 'Катки для щебеночного покрытия (макадам)',
      CRAWLER_DUMP: 'Гусеничные самосвалы',
      CRAWLER_CARRIER: 'Гусеничные перевозчики',
      CRAWLER_TRUCK: 'Гусеничные грузовики',
      WHEEL_CARRIER: 'Колесные мини-самосвалы',
      GENERATOR: 'Генераторы',
      WELDER_GENERATOR: 'Сварочные генераторы',
      AGRICULTURAL_MACHINE_ETC: 'Сельхоз машины и т.д.',
      CONSTRUCTION_MACHINE_ETC: 'Строительные машины и т.д.',
      OFFROAD_DUMP_TRUCK: 'Внедорожные самосвалы',
      SNOW_REMOVING_MACHINE: 'Снегоуборочные машины',
      STABILIZER: 'Навесные стабилизаторы ',
      SPECIAL_PURPOSE_VEHICLE: 'Техника специального назначения',
      ROAD_CUTTER: 'Швонарезчик',
      TURRET_TRUCK: 'Башенные погрузчики',
      LOGGING_TRACTOR: 'Трактора для лесозаготовки',
      TOWING_TRACTOR: 'Трактора тягачи',
      SMALL_MACHINES: 'Маленькие машины',
      TRACTOR: 'Тракторы',
      COMBINE: 'Комбайны',
      AGRICULTURAL_MACHINE_ATTACHMENT: 'Навесное оборудование для сельхоз машин',
    },
  },

  PRESET_UNSUBSCRIBE: {
    UNSUBSCRIBING: 'Отписка...',
    SUCCESS: 'Отписка от рассылки подборок завершена',
    FAIL: 'Настройки фильтра не найдены',
    GO_HOME: 'На главную',
  },

  LOT_PROBLEM_ALERTS: {
    LOT_DATA_CHANGED: 'Лот заменен, ставки не принимаются',
    BIDS_LIMIT_REACHED: 'Лимит лотов на день достигнут',
    LOT_BIDS_LIMIT_REACHED: 'Лимит ставок на лот достигнут',
  },

  CONNECT_TELEGRAM: {
    TITLE: 'Подключение Telegram',
    CONNECT_BUTTON: 'Подключить уведомления через Telegram',
  },

  SUBSCRIBE_TO_BEST_BID: {
    CONFIRMATION_1:
      'Услуга "Показ ставок" позволяет видеть текущую ставку на лот другого клиента в течении 30 дней. Для подключения услуги необходимо совершить 10 и более покупок за последние 30 дней.',
    CONFIRMATION_2:
      'Стоимость составляет <span class="red">100,000 йен</span>, которые будут списаны с вашего аванса.',
    ATTENTION: 'Внимание!',
    OK_TEXT: 'Подключить',
    TITLE: 'Показать ставку',
    DAYS_LEFT: 'Осталось {{days}} дней',
    NO_ACTIVE_SUB: 'Нет активной подписки',
    HAS_ACTIVE_SUBSCRIPTION: 'Есть активная подписка на просмотр лучшей ставки',
    SUCCESS: 'Услуга успешно подключена',
    SUCCESS_WHEN_ADVANCE_UPDATE:
      'Услуга будет подключена спустя некоторое время, когда придет аванс',
    SUCCESS_REQUEST: 'Запрос на подключение подписки, когда придет аванс создан',
    FAIL: 'Ошибка при подключении услуги. Обратитесь к менеджеру.',
    MODAL_TITLE: 'Соглашение',
    AGREEMENT_TIMER: 'Подключить услугу можно будет через {{seconds}}',
    AGREE: 'С условиями подключения и стоимостью согласен',
    SUB_ENDS_TODAY: 'Подписка заканчивается сегодня',
    SUBSCRIBE_ON_ADVANCE_UPDATE: 'Поключить, когда придет аванс',
    REQUEST_SUBSCRIBE_ON_ADVANCE_UPDATE: 'Запросить подключение, когда придет аванс',
    CANT_BUY_ALERT: 'У вас недостаточно средств на авансе для приобретения услуги',
  },

  LOT_COUNTERS: {
    VIEWS_COUNT: 'Количество просмотров: {{count}}',
    IN_FAVORITES_COUNT: 'Добавили в избранное: {{count}}',
    TOOLTIP:
      '{{bidders}} клиент{{biddersEnding}} сделал{{makeEnding}} {{bids}} став{{bidsEnding}} на данный лот',
    BIDDERS_ENDING_1: '',
    BIDDERS_ENDING_2: 'а',
    BIDDERS_ENDING_5: 'ов',
    BIDS_ENDING_1: 'ку',
    BIDS_ENDING_2: 'ки',
    BIDS_ENDING_5: 'ок',
    MAKE_ENDING_1: '',
    MAKE_ENDING_2: 'и',
  },

  MAX_BID_COMMISSION: {
    COMMISSION: 'Комиссия',
    YOU_BID_ON_LOT: 'Ваша ставка на лот составит',
    ADDITIONAL_DEALER_COMMISSION: 'Дополнительная комиссия дилера',
    AGREE_WITH_COMMISSION: 'С дополнительной комиссией согласен',
    MINIMUM: 'Минимум',
    MINIMUM_DEALER_COMMISSION: 'Минимальная комиссия дилера',
    MINIMUM_LOT_BID: 'Ставка должна быть {{firstBound}} или менее, либо {{secondBound}} и более.',
  },

  BIRTHDATE_MODAL: {
    TITLE: 'Дата рождения',
    INVALID_DATE: 'Не реальная дата рождения',
    ALERT: 'Чтобы продолжить необходимо указать дату рождения в формате год-месяц-день',
  },

  RESET_LOT: {
    TITLE: 'Обновить данные лота',
    WARNING:
      'Обновление данных лота приведет к удалению: ставок на лот, комментариев к ставкам, пользовательских запросов связанных со ставками, пользовательских запросов связанных с лотом, переводов лота. Вы точно хотите обновить лот?',
  },

  AUCTION_RATING: {
    REVIEWS: 'Отзывы',
    REVIEWS_AUCTION: 'Отзывы о {{auctionName}}',
  },

  USER_PURCHASE_STATISTICS: {
    BOUGHT_TODAY: 'Покупки сегодня',
    BOUGHT_YESTERDAY: 'Покупки вчера',
    BOUGHT_WEEK: 'Покупки неделя',
  },

  VAT: 'Внимание! Эта покупка будет облагаться 10% НДС',

  MENU: 'Меню',
  NEWS: 'Новости',
  TO_FULL_LOT: 'Полный лот',
  Целая: 'Целая',
  Спецтехника: 'Спецтехника',
  Мотоцикл: 'Мотоцикл',
  SANCTIONED_LOT_NOT_ALLOWED: 'Санкционные лоты недоступны к просмотру в вашей стране',
  'Request already created for this lot': 'Запрос на этот лот уже существует',
  'Приём ставок на лот окончен!': 'Приём ставок на лот окончен!',
  'Конструктор 3 000 евро': 'Конструктор 3 000 евро',
  'Запчасти (палеты)': 'Запчасти (палеты)',
  'Запчасти контейнер': 'Запчасти контейнер',
  'Другие страны': 'Другие страны',
  'Другие страны MOTO': 'Другие страны только мотоциклы',
  'Грузовик-конструктор': 'Грузовик-конструктор',
  '(Борт) Распил перед раму пилим': '(Борт) Распил перед раму пилим',
  '(Борт) Распил передний': '(Борт) Распил передний',
  '(Борт) Распил зад раму пилим': '(Борт) Распил зад раму пилим',
  '(Борт) Распил задний': '(Борт) Распил задний',
  '(Борт) Распил вместе с рамой': '(Борт) Распил вместе с рамой',
  '(Борт) Передняя половина без крыльев': '(Борт) Передняя половина без крыльев',
  '(Борт) Конструктор': '(Борт) Конструктор',
  '(Борт) каркас': '(Борт) каркас',
  '(Берег) Распил перед раму пилим': '(Берег) Распил перед раму пилим',
  '(Берег) Распил передний': '(Берег) Распил передний',
  '(Берег) Распил зад раму пилим': '(Берег) Распил зад раму пилим',
  '(Берег) Распил задний': '(Берег) Распил задний',
  '(Берег) Распил вместе с рамой': '(Берег) Распил вместе с рамой',
  '(Берег) Передняя половинка': '(Берег) Передняя половинка',
  '(Берег) Конструктор': '(Берег) Конструктор',
  '(Берег) Каркас': '(Берег) Каркас',
  'Сумма ставки должна быть положительной': 'Сумма ставки должна быть положительной',
  'Сумма ставки должна быть выше стартовой цены': 'Сумма ставки должна быть выше стартовой цены',
  'Ваша ставка меньше ставки другого участника торгов.':
    'Ваша ставка меньше ставки другого участника торгов.',
  'Ваша ставка меньше ставки другого участника торгов. Вы можете поднять свою ставку, попыток осталось:':
    'Ваша ставка меньше ставки другого участника торгов. Вы можете поднять свою ставку, попыток осталось: {{triesLeft}}',

  LowBidError: 'Ваша ставка меньше ставки другого участника',
  Error: 'Ошибка',
  'Слишком много ставок выше вашей, ставка не имеет возможности победить, извините':
    'Слишком много ставок выше вашей, ставка не имеет возможности победить, извините',
  'Для данного лота не осталось попыток повышения ставки':
    'Для данного лота не осталось попыток повышения ставки',
  'Данные лота устарели, обновите страницу': 'Данные лота устарели, обновите страницу',
  'В данном лоте уже есть победитель': 'В данном лоте уже есть победитель',
  'No Result From API': 'Не получилось определить дату производства',
  'Incorrect Chassis No.': 'Некоррестный номер шасси',
  'Chassis number entered in not correct. Check on auction sheet to confirm the chassis number.':
    'Номер шасси введен неверно. Проверьте на аукционном листе, чтобы подтвердить номер шасси.',
  'Недостаточно прав для выполнения действия':
    'Недостаточно прав. Только пользователи с доступом к торгам могут выполнять данную операцию. Обратитесь к менеджеру.',
  'Среднее время доставки с данного аукциона до:': 'Среднее время доставки с данного аукциона до:',
  'Среднее время доставки документов с данного аукциона:':
    'Среднее время доставки документов с данного аукциона:',
  'в зависимости от доставщика': 'в зависимости от доставщика',
  'У вас нет прав для перепроверки даты выпуска': 'У вас нет прав для перепроверки даты выпуска',
  дней: 'дней',
  'Если это Ваш платеж пожалуйста срочно отзовитесь':
    'Если это Ваш платеж пожалуйста срочно отзовитесь',
  'Request for this lot already exists': 'Запрос на этот лот уже существует',
  Производитель: 'Производитель',
  Модель: 'Модель',
  Кузов: 'Кузов',
  Аукцион: 'Аукцион',
  Оценка: 'Оценка',
  Цвет: 'Цвет',
  КПП: 'КПП',
  'год от, до': 'год от, до',
  'Пробег от, до (тыс.км)': 'Пробег от, до (тыс.км)',
  'Объем двигат. от, до': 'Объем двигат. от, до',
  Сброс: 'Сброс',
  Поиск: 'Поиск',
  PRINT: 'Печать',
  'Расширенный поиск': 'Расширенный поиск',
  Дополнительно: 'Дополнительно',
  'Японские автоаукционы': 'Японские автоаукционы',
  SAVE_PRESET: 'Сохранить шаблон',
  CREATE_USER_FILTER_PRESET: 'Создать шаблон фильтра',
  MARKED_INTERESTING: 'Интересные',
  MARKED_UNINTERESTING: 'Неинтересные',
  'Not Sold': 'Не продан',
  成約: 'Продан',
  流れ: 'Не продан',
  商談成約: 'Продан по переговрам',
  'Request already created': 'Запрос уже создан',
  'Lot not found': 'Фото лота не найдены',
  'Filter preset not found': 'Настройка фильтра не найдена',
  'Достигнут лимит ставок в этой группе': 'Достигнут лимит ставок в этой группе',
  BIDS: 'Ставки',
  USER: 'Пользователь',
  LOT_BIDS_TABLE_TITLE: 'Всего ставок: {{bidsTotal}}. Пользователей участвовало: {{usersTotal}}',
  DETAILS: 'Подробно',
  NEGOTIATIONS_CONFIRM_1: `Вы вступаете в переговоры по лоту. Отменить это решение невозможно. Уверены что хотите продолжить? <br /> {{link}}`,
  NEGOTIATIONS_CONFIRM_2:
    'Минимальная ставка необходимая для вступления в переговоры - {{sum}}. Надбавка в ¥20,000 необходима для увеличения шансов в торгах. Ставка для вступления в переговоры не может быть ниже предложенной. Пожалуйста, в поле ниже укажите ставку не ниже {{sum}}',
  NEGOTIATIONS_WORK: 'Как работают переговоры по лоту',
  NEGOTIATIONS_BID_TOO_SMALL_ERROR: 'Размер ставки не может быть меньше {{amount}}',
  NEGOTIATIONS_BID_TOO_LARGE_ERROR: 'Размер ставки не может быть больше {{amount}}',
  NEGOTIATIONS_BID_REQUIRED_ERROR: 'Необходимо указать размер ставки для переговоров',
  'Участие в торгах': 'Участие в торгах',
  'Ожидает подтверждения для участия в торгах': 'Ожидает подтверждения для участия в торгах',
  Отклонено: 'Отклонено',
  'Подтверждено участие в торгах': 'Подтверждено участие в торгах',

  TICKET_RULES: 'Правила подачи претензий',
  EXPECTED_TIME_TO_TRANSLATE: 'Среднее время перевода на данный аукцион в рабочее время по Японии',

  'Номер лота': 'Номер лота',
  'Please select your country': 'Пожалуйста укажите вашу страну',
  'Cant plug subscription to view best bid':
    'Не удалось подключить подписку на просмотр лучшей ставки',

  PRODUCTION_DATE_CHECK_ERROR: 'Не удалось проверить дату производства',
  'information not found': 'Данные отсутствуют в имеющихся каталогах',
  'company not found': 'Данные отсутствуют в имеющихся каталогах',
  PRODUCTION_DATE_CHECK_REQUEST_LIMIT_REACHED:
    'Достигнут лимит запросов на пробитие даты производства',
  NO_TARGET_AUCTION_TOOLTIP:
    'Если нужный аукцион отсутствует в списке, пожалуйста, свяжитесь с вашим менеджером',
  NOTATIONS: 'Обозначения',
  PROFILE: {
    USER_PROFILE: 'Профиль пользователя',
    GENERAL: 'Основные',
    TIMEZONE: 'Временная зона',
    UPDATE: 'Обновить',
    FOR_CHAT: 'Для уведомлений чата',
    SECURITY: 'Безопасность',
    PASSWORD_RULES:
      'Пароль должен содержать только латинские буквы, одну заглавную букву, одну цифру и быть не менее 8 символов',
    ENTER_OLD_PASS: 'Введите старый пароль',
    ENTER_NEW_PASS: 'Введите новый пароль',
    REPEAT_NEW_PASS: 'Повторите новый пароль',
    FIELD_REQUIRED: 'Поле "{{field}}" является обязательным',
    RULES_ERROR: 'Условия не соблюдены',
    PASS_MISSMATCH: 'Пароли не совпадают',
    CHANGE: 'Сменить',
    LANGUAGE: 'Язык',
    EMAIL_INCORRECT: 'Некорректный email',
    ADD_EMAIL: 'Добавить email',
    VALIDATION_FAIL: 'Некоторые поля заполнены некорректно',
    UPDATE_SUCCESS: 'Профиль успешно обновлен',
    UPDATE_FAIL: 'Не удалось обновить профиль',
    TOO_SHORT: 'Пароль слишком короткий',
    ONLY_LATIN: 'Пароль должен содержать только латинские буквы',
    CAPITAL_CHAR: 'Пароль должен содержать хотя бы одну заглавную букву',
    ONE_DIGIT: 'Пароль должен содержать хотя бы одну цифру',
    CHANGE_PASS_REQUIRED: 'Вы должны сменить пароль',
    LANGUAGES: {
      ru: 'Русский',
      en: 'English',
    },
    SUBSCRIBE_TO: 'Подключить уведомления через {{name}}',
    NOTIFICATION_BY: 'Уведомления от {{name}}',
    SUBSCRIBE: 'Подключить',
    NOTIFICATION_TYPE: 'Тип уведомлений',
    NOTIFICATIONS: 'Уведомления',
    CONNECT_SUBSCRIBE: 'Подключить уведомления через Telegram',
    DELETE_NOTIFICATION_CHAT: 'Удалить подключенный Telegram',
    CANCEL_DELETE_NOTIFICATION_CHAT: 'Отмена',
    CONFIRM_DELETE_NOTIFICATION_CHAT: 'Удалить',
  },

  AUTO_FILTER_GUIDE: {
    0: {
      TITLE: 'День недели',
      TEXT: 'Вы можете выбрать желаемый день недели торгов',
    },
    1: {
      TITLE: 'Производитель',
      TEXT: 'Выберите производителя автомобиля, чтобы сузить список доступных моделей. Это поможет вам быстрее найти авто от вашего любимого бренда',
    },
    2: {
      TITLE: 'Модель',
      TEXT: 'Выберите конкретную модель автомобиля в рамках производителя',
    },
    3: {
      TITLE: 'Кузов',
      TEXT: 'Выберите предпочитаемый кузов',
    },
    4: {
      TITLE: 'Аукцион',
      TEXT: 'Если вас интересует конкретный аукцион, вы можете указать его',
    },
    5: {
      TITLE: 'Оценка',
      TEXT: 'Оценка автомобиля на аукционе. подробнее об оценках вы можете узнать в разделе F.A.Q. Ссылка в шапке сайта',
    },
    6: {
      TITLE: 'Цвет ТС и КПП',
      TEXT: 'Укажите предпочитаемый цвет ТС и тип коробки передач',
    },
    7: {
      TITLE: 'Год, пробег и объем двигателя',
      TEXT: 'Объем и пробег указываются цифрами, полностью, например: 1500 или 75000',
    },
    8: {
      TITLE: 'Номер лота',
      TEXT: 'Если вас интересует определенный лот, можно указать его номер здесь',
    },
    9: {
      TITLE: 'Тип грузовика',
      TEXT: 'Вы можете искать грузовики по конкретным типам. Например - кран, мусоровоз, автобус, миксер бетона и т.д.',
    },
    10: {
      TITLE: 'Комплектация',
      TEXT: 'Укажите желаемую комплектацию',
    },
    11: {
      TITLE: 'Расположение руля',
      TEXT: 'Вы можете посмотреть авто с определенным расположением руля',
    },
    12: {
      TITLE: 'Дата производства',
      TEXT: 'Поиск по дате производства происходит только по тем лотам, в которых у нас есть эти данные',
    },
    13: {
      TITLE: 'Аукцион не начался',
      TEXT: 'Показать только аукционы, где торги еще не начались',
    },
    14: {
      TITLE: 'Сохранить шаблон',
      TEXT: 'После того как вы применили фильтр, можно сохранить его чтобы в будущем применять в один клик',
    },
    15: {
      TITLE: 'Действия с фильтром',
      TEXT: 'Очистите текущий фильтр или примените его',
    },
    16: {
      TITLE: 'Расширенный поиск',
      TEXT: 'Выберите любой аукцион исходя из дня когда он торгуется',
    },
  },

  BODY_DEFECTS: {
    TITLE: 'Дефекты кузова',
    A1: 'A1 - Маленькая царапина по лаку',
    A2: 'A2 - Царапина по краске',
    A3: 'A3 - Большая царапина, возможно по металлу',
    A4: 'А4 - Царапина больше 20 см',
    A5: 'А5 - Покрывает всю панель или больше',
    B: 'B - Вмятина с царапиной',
    E1: 'E1 - Небольшая Вмятина',
    E2: 'E2 - Несколько Небольших Вмятин',
    E3: 'E3 - Много Небольших Вмятин',
    U1: 'U1 - Маленькая Вмятина',
    U2: 'U2 - Вмятина',
    U3: 'U3 - Большая Вмятина',
    W1: 'W1 - Ремонт/Покраска (едва обнаружимые)',
    W2: 'W2 - Ремонт/Покраска (заметные)',
    W3: 'W3 - Ремонт/Покраска (очень заметные, должно быть перекрашено)',
    S1: 'S1 - Малозаметная Ржавчина',
    S2: 'S2 - Ржавчина',
    C1: 'C1 - Коррозия',
    C2: 'C2 - Заметная Коррозия',
    P: 'P - Краска отличается от оригинала',
    H: 'H - Краска ухудшилась',
    X: 'X - Элемент требует замены',
    XX: 'XX - Замененный Элемент',
    B1: 'B1 - Небольшой дефект на бамперах (приблизительно размер большого пальца)',
    B2: 'B2 - Заметный дефект на бамперах',
    Y1: 'Y1 - Маленькая Трещина',
    Y2: 'Y2 - Трещина',
    Y3: 'Y3 - Большая Трещина',
    X1: 'X1 - Маленькая Трещина на Ветровом стекле (приблизительно 1 см)',
    R: 'R - Восстановленная Трещина на Ветровом стекле',
    RX: 'RX - Восстановленная Трещина на Ветровом стекле (требует замены)',
    X_CORRECTION: 'X - Трещина на Ветровом стекле (требует замены)',
    G: 'G - Трещина в стекле от камня',
  },
  IMPORT_BIDS: {
    TITLE: 'Импорт ставок',
    ALERT:
      'Здесь вы можете импортировать свои ставки в систему EcarJP. Импорт происходит из файла - <a download href="/assets/import-bids-example.xls"><b>скачать шаблон</b></a>. Поля <b>User</b> и <b>Group</b> не обязательны к заполнению. После загрузки файла система проанализирует какие из лотов доступны для торгов. Вы сможете указать состояние, группу и необходимость уведомления по результатам торгов. По итогам импорта система подскажет вам какие ставки были успешно проставлены, а в каких ставка не возможна в связи с <a href="https://ecarjp.com/article/bid-rules" target="_blank"><b>условиями постановки ставок</b></a>.',
    UPLOAD_TIP: 'Щелкните или перетащите файл в эту область для загрузки',
    BID: 'Ставка',
    GROUP: 'Группа',
    STATUS: 'Статус',
    LOT_NOT_FOUND: 'не найден',
    BIDDABLE: 'Ставка возможна',
    MUTATION_ERROR: 'Ошибка ставки: {{error}}',
    PLACING: 'Выставление ставок',
    SUCCESS: 'Успешно',
    FAIL: 'Не удачно',
    SUCCESSFULLY_PLACED: 'Успешно выставлена',

    TABLE_TITLE: {
      PARSED_BIDS_COUNT: 'ставок получено',
      PLACE_BIDS: 'Поставить ставки',
      PLACE_SELECTED_BIDS: 'Поставить выбранные',
      RESET: 'Сбросить',
    },

    PARSING_ERRORS: {
      UNEXPECTED_ERROR: 'Произошла непредвиденная ошибка',
      INVALID_FIELD: 'Поле "{{field}}" не заполнено или имеет не верный формат',
    },

    ERRORS: {
      'bid-amount-is-not-multiple-5k': 'Ставка должна быть кратна 5000',
      'bid-amount-less-then-start-price': 'Ставка меньше стартовой цены',
      'impossible-place-group-bid': 'Невозможно сделать групповую ставку',
      'group-limits-reached': 'Достигнуты лимиты группы',
      'no-available-bid-condition': 'Нету подходящего стостояния ставки',
      'lot-sanctioned': 'Санкционный лот',
      'moto-overprice': 'Лот снят с торгов',
      'time-over': 'Торги окончены',
      'lots-limit': 'Достигнут лимит лотов',
      'bids-limit': 'Достигнут лимит ставок',
    },
  },
  WATCH_REQUEST: {
    TITLE: 'Запросить осмотр',
    ALERT:
      'Вы можете запросить дополнительный фото-осмотр лота нашим сотрудником. Стоимость услуги - <b>{{price}} ¥</b>. Средства будут списаны с вашего баланса. При отсутствии суммы на балансе, средства будут списаны с вашего депозита. Заявки обрабатываются в порядке очереди. Как только ваш запрос будет выполнен на вашу почту придет ссылка с результатами фото-осмотра.',
    ALREADY_CREATED: 'Вы уже отправляли запрос на фото-осмотр этого лота',
    COMMENT: 'Комментарий (обязательно)',
    SEND: 'Отправить запрос',
  },
};
